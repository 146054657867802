import * as React from 'react'

import * as styles from '@/components/Hostel/Information/Information.module.scss'

const HostelInformation = () => {
  return (
    <>
      <section className={styles['info']}>
        <h3 className={styles['title']}>お食事について</h3>
        <p className={styles['description']}>
          軽食を無人販売しています。
          <br />
          飲食の持ち込みは可能です。
          <br />
          電子レンジはご自由にお使いください。
          <br />
          景観維持のため、お部屋での飲食はお控えください。
          <br />
          1階のカフェはどなたでもご利用可能です。
          <br />
          営業日はカレンダーから確認できます。
        </p>
      </section>
      <section className={styles['info']}>
        <h3 className={styles['title']}>近隣施設について</h3>
        <p className={styles['description']}>
          徒歩2分先にある市民つつじホールでは、
          <br />
          大人料金420円でジムと大浴場の利用が可能です。
        </p>
      </section>
      <section className={`${styles['info']} ${styles['bottom']}`}>
        <h3 className={styles['title']}>注意事項</h3>
        <p className={styles['description']}>
          全館禁煙 / 22:00以降は騒音に気をつけてお楽しみください。
          <br />
          飲食は2階ラウンジと1階座席スペースでのみ可能です。
          <br />
          ゴミの分別にご協力ください。
          <br />
          トイレ・シャワー室付近での撮影はご遠慮ください。
          <br />
          ランドリーのご用意はありません。
          <br />
          玄関入り口と、ラウンジには監視カメラを設置してあります。
        </p>
      </section>
    </>
  )
}

export default HostelInformation
